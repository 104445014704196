import React from 'react'
import NavBarThree from './NavBars/NavBarThree'

export default function Header() {
  return (
    <div>
      <NavBarThree />
    </div>
  )
}
